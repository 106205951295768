<div (clickOutside)="close()"
     *ngIf="acceptableLanguages?.length"
     class="epic-language-switcher">
	<ul *ngIf="isOpen"
	    [@fadeInOut]
	    class="epic-language-switcher__list">
		<li (click)="$event.stopPropagation(); setLanguage(language)"
		    *ngFor="let language of acceptableLanguages"
		    [class.epicLanguageSwitcher__list-item--selected]="language == selectedLanguage"
		    class="epic-language-switcher__list-item">
			<div class="flag" [style.background-image]="getIconSource(language)"></div>
			<div class="lang-name">{{ getLanguageName(language) }}</div>
		</li>
	</ul>
	<span (click)="toggle()"
	      *ngIf="selectedLanguage"
	      class="epic-language-switcher__selected" [style.background-image]="getIconSource(selectedLanguage)">
    </span>
</div>