@if (fadeInOnEnterAnimationIsDisabled()) {
	<epic-loader
		*ngIf="isLoader"
		[@fadeOutOnLeave]
		[class.epic-loader__contain]="loaderSize == 'contain'" />
} @else {
	<epic-loader
		*ngIf="isLoader"
		[@fadeInOnEnter]
		[@fadeOutOnLeave]
		[class.epic-loader__contain]="loaderSize == 'contain'" />
}

<div class="epic-app-layout">
	<div class="epic-app-layout__menu">
		<ng-content select="[menu]" />
	</div>
	<div
		#layoutContent
		(scroll)="afterScroll($event)"
		class="epic-app-layout__content">
		<ng-content />
	</div>
</div>
