import { CommonModule } from '@angular/common'
import { Component, Input, QueryList, ViewChildren } from '@angular/core'
import { Router, RouterLink } from '@angular/router'
import { collapseAnimation } from '@core/animations'
import { IconComponent } from '@core/components/icon/icon.component'
import { ClickOutsideDirective } from '@core/directives/click-outside.directive'
import { MenuService } from '@core/services/menu.service'
import { MenuItem } from '@core/types/menu-item'
import { PopoverComponent } from '@core/components/popover/popover.component'
import { LoaderService } from '@core/services/loader.service'
import { TooltipDirective } from '@core/directives/tooltip.directive'

@Component({
	selector: 'epic-menu-item',
	standalone: true,
	imports: [CommonModule, IconComponent, RouterLink, ClickOutsideDirective, PopoverComponent, TooltipDirective],
	templateUrl: './menu-item.component.html',
	styleUrls: ['./menu-item.component.scss'],
	animations: [collapseAnimation()],
})
export class MenuItemComponent {
	@Input() options!: MenuItem
	@Input() isSubmenuItem = false
	@Input() collapseState = true
	@Input() animationDuration = 200
	@Input() closeAfterClickOutside = false

	@ViewChildren('popoverFirstLevel') popoversFirstLevel?: QueryList<PopoverComponent>
	@ViewChildren('popoverSecondLevel') popoversSecondLevel?: QueryList<PopoverComponent>

	protected readonly Array = Array

	constructor(
		private menuService: MenuService,
		private loaderService: LoaderService,
		private router: Router,
	) {}

	ngOnInit(): void {
		if (this.isAlternativeDesign) {
			this.collapseState = true
		}
	}

	/**
	 * Checks if the menu is expanded or collapsed.
	 *
	 * @returns {boolean} Returns a boolean indicating if the menu is expanded or collapsed.
	 */
	isMenuExpanded(): boolean {
		return !this.menuService.collapseValue()
	}

	get isAlternativeDesign(): boolean {
		return this.menuService.getLocalStorageMenuStyle === 'new'
	}

	isGroup(): boolean {
		return !!this.options?.children && !!this.options.children.length
	}

	isActive(): boolean {
		if (this.options?.isActive) {
			return this.options.isActive()
		} else {
			return false
		}
	}

	isExternalLink(): boolean {
		return this.options.isExternalLink === true
	}

	isButton(): boolean {
		if (!this.isMenuExpanded()) {
			return false
		} else {
			return !!this.options?.button
		}
	}

	isAfterClickFunction(): boolean {
		return !!this.options.afterClick
	}

	afterClick(): void {
		if (this.options.afterClick) {
			this.options.afterClick()
		}
	}

	afterButtonClick(): void {
		if (this.options.button) {
			if (this.options.button.afterClick) {
				this.options.button.afterClick()
			}
		}
	}

	isIcon(): boolean {
		return !this.isSubmenuItem && (!!this.options?.iconName || !!this.options?.iconSource)
	}

	open(url?: string): void {
		// Close second level popover on click
		this.popoversSecondLevel?.toArray().forEach(p => p.closePopover())

		if (url) {
			const serializedUrl = this.router.serializeUrl(this.router.createUrlTree([url]))
			if (serializedUrl != this.router.url) {
				this.loaderService.setContain().enable(async () => {
					await this.router.navigateByUrl(url, {
						onSameUrlNavigation: 'ignore',
					})
				})
			}
		}
	}

	openInNewTab(url?: string): void {
		const serializedUrl = this.router.serializeUrl(this.router.createUrlTree([url]))
		window.open(serializedUrl, '_blank')
	}

	clickOutside() {
		if (this.closeAfterClickOutside) {
			this.collapseState = true
		}
	}

	onClickPopoverElement(item: MenuItem): void {
		this.popoversFirstLevel?.toArray().forEach(p => p.closePopover())
		this.popoversSecondLevel?.toArray().forEach(p => p.closePopover())

		item.afterClick ? item.afterClick() : this.open(item.link)
	}
}
