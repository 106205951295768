import { Component, HostListener } from '@angular/core'
import { AppLayout } from '@core/layouts/app/app.layout'
import { MenuComponent } from '@core/components/menu/menu.component'
import { HeadingComponent } from '@core/components/heading/heading.component'
import { Router, RouterOutlet } from '@angular/router'
import { MenuItemComponent } from '@core/components/menu-item/menu-item.component'
import { MenuItem } from '@core/types/menu-item'
import { AuthService } from '@app/auth/services/auth.service'
import { AppService } from '@app/general/services/app.service'
import { LoaderService } from '@core/services/loader.service'
import { config } from '@config'
import { Permission } from '@app/acl/enums/permissions.enum'
import { SignaComponent } from '@app/general/classes/signa-component'
import { NgIf } from '@angular/common'
import { MenuService } from '@core/services/menu.service'
import { AvatarComponent } from '@app/users/components/avatar/avatar.component'
import { ChatModule } from '@app/chat/chat.module'
import { MailboxInnerService } from '@app/mailbox/services/mailbox-inner.service'
import { MailIMAPAccountRead, MailService, UsersService } from '@app/generated'
import { OrdersInnerService } from '@app/orders/services/orders-inner.service'
import { ContractorInnerService } from '@app/contractors/services/contractor-inner.service'
import { RouterService } from '@app/general/services/router.service'
import { ShortcutService } from '@app/general/components/tutorial/shortcut.service'
import { NotificationService } from '@core/services/notification.service'

@Component({
	selector: 'app-app-navigation',
	standalone: true,
	imports: [
		RouterOutlet,
		AppLayout,
		MenuComponent,
		HeadingComponent,
		MenuItemComponent,
		NgIf,
		AvatarComponent,
		ChatModule,
	],
	template: `
		<app-layout>
			<app-chat-modal></app-chat-modal>
			<epic-menu menu>
				<div
					class="epic-menu__top"
					menu-top>
					<div
						(click)="goToHome()"
						class="epic-menu__top-logo c-pointer"
						[class.collapsed]="menuService.collapseValue()">
						@if (menuService.collapseValue()) {
							<img
								alt="menu icon"
								src="assets/logo-mini.png" />
						} @else {
							<img
								alt="menu icon"
								src="assets/logo.png" />
						}
					</div>
					<div
						class="epic-menu__user"
						[class.collapsed]="menuService.collapseValue()"
						(click)="openProfile()">
						<div
							class="epic-menu__user-row"
							[class.collapsed]="menuService.collapseValue()">
							<div class="menu-avatar">
								<avatar [avatarName]="avatarName()" />
							</div>
							@if (!menuService.collapseValue()) {
								<div class="epic-menu__user-wrapper">
									<span>{{ getUserName }}</span>
									<span class="epic-menu__user-role font__medium">{{ getUserRole }}</span>
								</div>
							}
						</div>
					</div>
				</div>
				@for (item of menuItems; track item) {
					<epic-menu-item
						*ngIf="item.isVisible"
						menu-item
						[collapseState]="false"
						[closeAfterClickOutside]="false"
						[options]="item" />
				}
				@for (item of menuItemsBottom; track item) {
					<epic-menu-item
						*ngIf="item.isVisible"
						menu-bottom
						[collapseState]="true"
						[closeAfterClickOutside]="false"
						[options]="item" />
				}
			</epic-menu>
			<div class="page-container">
				@if (appService.title !== undefined) {
					<epic-heading
						[buttonOptions]="appService.buttonOptions"
						[actionButtonOptions]="appService.actionButtonOptions"
						[text]="appService.title"
						[hideClock]="hideClock()"
						[headerClass]="appService.headerClass"
						[isBlocked]="appService.isBlocked" />
				}
				<router-outlet content />
			</div>
		</app-layout>
	`,
})
export class AppNavigationComponent extends SignaComponent {
	mailAccountsList: MailIMAPAccountRead[] = []
	menuItemsBottom: MenuItem[] = [
		{
			text: $localize`Settings`,
			iconName: 'settings',
			isVisible: true,
			keyboardShortcut: {
				shortcut: 'Control.Shift.9',
				action: () => {
					this.routerService.navigateByUrl('/settings', { page: 'general' })
				},
			},
			children: [
				{
					text: $localize`General`,
					link: '/settings?page=general',
					isVisible: true,
				},
				{
					text: $localize`Notifications`,
					link: '/settings?page=notifications',
					isVisible: true,
				},
				{
					text: $localize`Keyboard shortcuts`,
					link: '/settings?page=keyboard-shortcuts',
				},
				{
					text: $localize`Users`,
					link: '/settings?page=users',
					isVisible: this.showMenuItem([Permission.SETTINGS_USERS_READ]),
				},
				{
					text: $localize`Substitutions`,
					link: '/settings?page=replacements',
					isVisible: this.showMenuItem([Permission.REPLACEMENT_READ]),
				},
				{
					text: $localize`Roles`,
					link: '/settings?page=roles',
					isVisible: this.showMenuItem([Permission.ROLES_READ]),
				},
				{
					text: $localize`Dictionaries`,
					link: '/settings?page=dictionaries',
					isVisible: this.showMenuItem([Permission.SETTINGS_DICTIONARIES_READ]),
				},
				{
					text: $localize`Order templates`,
					link: '/settings?page=order-templates',
					isVisible: this.showMenuItem([Permission.ORDER_TEMPLATES_READ]),
				},
				{
					text: $localize`Task templates`,
					link: '/settings?page=task-templates',
					isVisible: this.showMenuItem([Permission.TASK_TEMPLATES_READ]),
				},
				{
					text: $localize`Regulations`,
					link: '/settings?page=regulations',
					isVisible: this.showMenuItem([Permission.REGULATIONS_READ]),
				},
				{
					text: $localize`Price-list`,
					link: '/settings?page=margin',
					isVisible: this.showMenuItem([Permission.MARGIN_MANAGEMENT]),
				},
			],
		},
		{
			text: $localize`Chat`,
			iconName: 'chat-square-dots',
			link: '/chat',
			isVisible: this.showMenuItem([Permission.MESSAGES_READ]),
			keyboardShortcut: {
				shortcut: 'Control.Shift.0',
				action: () => {
					this.routerService.navigateByUrl('/chat')
				},
			},
		},
		{
			text: $localize`Reports`,
			iconName: 'raports',
			link: '/reports',
			isVisible: this.showMenuItem([Permission.ADMIN]),
		},
		{
			menuItemName: 'mailbox',
			text: $localize`Mailbox`,
			newItemAction: () => {
				this.loaderService.setContain().enable(async () => {
					await this.router.navigate(['/mailbox/new'])
				})
			},
			iconName: 'mail',
			isVisible: this.showMenuItem([Permission.MAIL_READ]),
			children: [
				{
					text: $localize`New email`,
					link: '/mailbox/new',
					isVisible: this.showMenuItem([Permission.MAIL_CREATE]),
				},
				{
					text: $localize`Inbox`,
					link: '/mailbox/inbox',
					isVisible: true,
				},
				{
					text: $localize`Outbox`,
					link: '/mailbox/outbox',
					isVisible: true,
				},
				{
					text: $localize`Drafts`,
					link: '/mailbox/drafts',
					isVisible: true,
				},
				{
					text: $localize`Contacts`,
					link: '/mailbox/contacts',
					isVisible: true,
				},
				{
					text: $localize`Mailbox settings`,
					link: '/mailbox/settings',
					isVisible: true,
				},
			],
		},
		{
			text: $localize`Log out`,
			iconName: 'logout',
			afterClick: () => this.authService.logout(),
			isVisible: true,
		},
	]
	menuItems: MenuItem[] = [
		{
			menuItemName: 'dashboard',
			text: $localize`Dashboard`,
			link: '/home',
			isVisible: true,
			isAlwaysFocused: true,
			iconName: 'dashboard',
			newItemAction: () => {
				this.loaderService.setContain().enable(async () => {
					await this.router.navigate(['/notifications'])
				})
			},
			keyboardShortcut: {
				shortcut: 'Control.Shift.1',
				action: () => {
					this.routerService.navigateByUrl('/home')
				},
			},
		},
		{
			menuItemName: 'orders',
			text: $localize`Orders`,
			iconName: 'kanban',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([Permission.ORDERS_READ]),
			newItemAction: this.showMenuItem([Permission.ORDERS_CREATE])
				? () => {
						const serializedUrl = this.router.serializeUrl(this.router.createUrlTree(['/orders/create']))
						if (serializedUrl != this.router.url) {
							this.loaderService.setContain().enable(async () => {
								await this.router.navigateByUrl(serializedUrl)
							})
						}
					}
				: undefined,
			children: [
				{
					menuItemName: 'ordersList',
					text: $localize`Orders list`,
					link: '/orders',
					isVisible: this.showMenuItem([Permission.ORDERS_READ]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.@',
						displayedShortcut: 'Control.Shift.2',
						action: () => {
							this.routerService.navigateByUrl('/orders')
						},
					},
				},
				{
					text: $localize`Add new order`,
					link: '/orders/create',
					isVisible: this.showMenuItem([Permission.ORDERS_CREATE]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.E',
						action: () => {
							this.routerService.navigateByUrl('/orders/create')
						},
					},
				},
			],
		},
		{
			menuItemName: 'contractors',
			text: $localize`List of groups of contractors`,
			iconName: 'customers',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([
				Permission.CONTRACTORS_READ,
				Permission.ENTITIES_READ,
				Permission.CONTRACTORS_CREATE,
				Permission.ENTITIES_CREATE,
			]),
			newItemAction: () => {
				const serializedUrl = this.router.serializeUrl(this.router.createUrlTree(['/contractors/create']))
				if (serializedUrl != this.router.url) {
					this.loaderService.setContain().enable(async () => {
						await this.router.navigateByUrl(serializedUrl)
					})
				}
			},
			children: [
				{
					menuItemName: 'contractorsList',
					text: $localize`List of groups of contractors`,
					link: '/contractors/groups',
					isVisible: this.showMenuItem([Permission.CONTRACTORS_READ]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.3',
						action: () => {
							this.routerService.navigateByUrl('/contractors/groups')
						},
					},
				},
				{
					menuItemName: 'entitiesList',
					text: $localize`List of contractors`,
					link: '/contractors',
					isVisible: this.showMenuItem([Permission.ENTITIES_READ]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.4',
						action: () => {
							this.routerService.navigateByUrl('/contractors')
						},
					},
				},
				{
					text: $localize`Add contractor`,
					link: '/contractors/create',
					isVisible: this.showMenuItem([Permission.CONTRACTORS_CREATE, Permission.ENTITIES_CREATE]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.D',
						action: () => {
							this.routerService.navigateByUrl('/contractors/create')
						},
					},
				},
			],
		},
		{
			text: $localize`Sales Documents`,
			iconName: 'sales-documents',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([Permission.INVOICES_READ, Permission.INVOICES_CREATE]),
			children: [
				{
					text: $localize`Sales documents list`,
					link: '/invoices',
					isVisible: this.showMenuItem([Permission.INVOICES_READ]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.5',
						action: () => {
							this.routerService.navigateByUrl('/invoices')
						},
					},
				},
				{
					text: $localize`Add invoice`,
					link: '/invoices/create',
					isVisible: this.showMenuItem([Permission.INVOICES_CREATE]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.T',
						action: () => {
							this.routerService.navigateByUrl('/invoices/create')
						},
					},
				},
				{
					text: $localize`Add a PROFORMA invoice`,
					link: '/invoices/create/proforma',
					isVisible: this.showMenuItem([Permission.INVOICES_CREATE]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.G',
						action: () => {
							this.routerService.navigateByUrl('/invoices/create/proforma')
						},
					},
				},
			],
		},
		{
			text: $localize`Cost documents list`,
			iconName: 'cost-documents',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([Permission.COST_INVOICES_READ, Permission.COST_INVOICES_CREATE]),
			children: [
				{
					text: $localize`Cost invoices list`,
					link: '/cost-invoices',
					isVisible: this.showMenuItem([Permission.COST_INVOICES_READ]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.^',
						displayedShortcut: 'Control.Shift.6',
						action: () => {
							this.routerService.navigateByUrl('/cost-invoices')
						},
					},
				},
				{
					text: $localize`Add a cost invoice`,
					link: '/cost-invoices/create',
					isVisible: this.showMenuItem([Permission.COST_INVOICES_CREATE]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.Y',
						action: () => {
							this.routerService.navigateByUrl('/cost-invoices/create')
						},
					},
				},
			],
		},
		{
			text: $localize`Cash documents`,
			iconName: 'cash-documents',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([Permission.CASH_RECEIPTS_READ, Permission.CASH_DISBURSEMENTS_READ]),
			children: [
				{
					text: $localize`Cash receipts list`,
					link: `${config.modules.cashDocuments.path}/cash-receipts`,
					isVisible: this.showMenuItem([Permission.CASH_RECEIPTS_READ]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.7',
						action: () => {
							this.routerService.navigateByUrl(`${config.modules.cashDocuments.path}/cash-receipts`)
						},
					},
				},
				{
					text: $localize`Cash disbursement list`,
					link: `${config.modules.cashDocuments.path}/cash-disbursement`,
					isVisible: this.showMenuItem([Permission.CASH_DISBURSEMENTS_READ]),
					keyboardShortcut: {
						shortcut: 'Control.Shift.8',
						action: () => {
							this.routerService.navigateByUrl(`${config.modules.cashDocuments.path}/cash-disbursement`)
						},
					},
				},
			],
		},
	]
	temp = false
	private userHasOrders = false

	constructor(
		private authService: AuthService,
		public appService: AppService,
		private loaderService: LoaderService,
		private router: Router,
		public menuService: MenuService,
		private mailboxInnerService: MailboxInnerService,
		private mailService: MailService,
		private ordersInnerService: OrdersInnerService,
		private contractorInnerService: ContractorInnerService,
		private routerService: RouterService,
		private shortcutService: ShortcutService,
		private notificationService: NotificationService,
		private usersService: UsersService,
	) {
		super()
		this.fetchUserHasNotifications()
		this.setMailsAccounts()
		this.fetchUserHasTasks()
		this.fetchUserHasContractors()
		this.mailboxInnerService.refreshMenuAccountsList$.subscribe(() => {
			this.setMailsAccounts()
		})

		this.menuItems.forEach(item => {
			if (item.isVisible) {
				if (item.keyboardShortcut) {
					this.shortcutService.shortcutMap.set(item.keyboardShortcut.shortcut, item.keyboardShortcut.action)
					this.shortcutService.shortcuts.push({
						text: item.text ?? '',
						shortcut: item.keyboardShortcut.shortcut,
						displayedShortcut: item.keyboardShortcut.displayedShortcut
							? item.keyboardShortcut.displayedShortcut
							: item.keyboardShortcut.shortcut,
					})
				}
				if (item.children) {
					item.children.forEach(child => {
						if (child.isVisible) {
							if (child.keyboardShortcut) {
								this.shortcutService.shortcutMap.set(child.keyboardShortcut.shortcut, child.keyboardShortcut.action)
								this.shortcutService.shortcuts.push({
									text: child.text ?? '',
									shortcut: child.keyboardShortcut.shortcut,
									displayedShortcut: child.keyboardShortcut.displayedShortcut
										? child.keyboardShortcut.displayedShortcut
										: child.keyboardShortcut.shortcut,
								})
							}
						}
					})
				}
			}
		})
		this.menuItemsBottom.forEach(item => {
			if (item.isVisible) {
				if (item.keyboardShortcut) {
					this.shortcutService.shortcutMap.set(item.keyboardShortcut.shortcut, item.keyboardShortcut.action)
					this.shortcutService.shortcuts.push({
						text: item.text ?? '',
						shortcut: item.keyboardShortcut.shortcut,
						displayedShortcut: item.keyboardShortcut.displayedShortcut
							? item.keyboardShortcut.displayedShortcut
							: item.keyboardShortcut.shortcut,
					})
				}
				if (item.children) {
					item.children.forEach(child => {
						if (child.isVisible) {
							if (child.keyboardShortcut) {
								this.shortcutService.shortcutMap.set(child.keyboardShortcut.shortcut, child.keyboardShortcut.action)
								this.shortcutService.shortcuts.push({
									text: child.text ?? '',
									shortcut: child.keyboardShortcut.shortcut,
									displayedShortcut: child.keyboardShortcut.displayedShortcut
										? child.keyboardShortcut.displayedShortcut
										: child.keyboardShortcut.shortcut,
								})
							}
						}
					})
				}
			}
		})
	}

	/**
	 * Retrieves the username from the decoded payload.
	 *
	 * @return {string} The username extracted from the decoded payload. If the payload does not contain the username, an empty string is returned.
	 */
	get getUserName(): string {
		return this.authService.decodePayload()?.name ?? ''
	}

	/**
	 * Retrieves the role(s) of the current user.
	 *
	 * @return {string} The role(s) of the user as a comma-separated string.
	 * If the user has no roles, an empty string is returned.
	 */
	get getUserRole(): string {
		if (!this.authService.decodePayload()?.roles?.length) {
			return ''
		}
		return this.authService
			.decodePayload()!
			.roles!.map(role => role.name)
			.join(', ')
	}

	@HostListener('window:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
		const shortcut = this.getShortcut(event)
		const mappedFunction = this.shortcutService.shortcutMap.get(shortcut)

		if (mappedFunction) {
			const obj = this.shortcutService.shortcuts.find(e => e.shortcut.toLowerCase() === shortcut.toLowerCase())
			if (obj) {
				this.notificationService.keyboardShortcut(obj.displayedShortcut)
			}

			event.preventDefault()
			mappedFunction()
		}
	}

	hideClock(): boolean {
		const serializedUrl = this.router.serializeUrl(this.router.createUrlTree(['/home']))
		return [serializedUrl].includes(this.router.url)
	}

	/**
	 * Navigates to the home page.
	 *
	 * @returns {void}
	 */
	goToHome(): void {
		const serializedUrl = this.router.serializeUrl(this.router.createUrlTree([`/${config.modules.home.path}`]))
		if (serializedUrl != this.router.url) {
			this.loaderService.setContain().enable(async () => {
				await this.router.navigateByUrl(serializedUrl)
			})
		}
	}

	/**
	 * Shows the menu item based on the provided permissions.
	 *
	 * @param {Permission[]} permissions - The array of permissions required to display the menu item.
	 * @returns {boolean} - Returns true if the menu item should be shown, false otherwise.
	 */
	showMenuItem(permissions?: Permission[]): boolean {
		if (!permissions) {
			return true
		}

		return this.aclService.hasPermission(permissions)
	}

	openProfile(): void {
		if (this.router.url != '/settings?page=general') {
			this.loaderService.setContain().enable(async () => {
				await this.router.navigateByUrl('/settings?page=general')
			})
		}
	}

	avatarName(): string | undefined {
		return this.authService.decodePayload()?.avatar
	}

	/**
	 * Fetches mail accounts from the mail service.
	 *
	 * @return {void} - This method does not return a value.
	 */
	setMailsAccounts(): void {
		if (!this.aclService.hasPermission(Permission.MAIL_READ)) {
			return
		}
		this.mailService.getImapAccounts(undefined, 0, 50, undefined, false, 'desc', false).subscribe({
			next: response => {
				this.mailAccountsList = response.items
				if (!this.mailAccountsList) {
					return
				}

				const menuItem = this.menuItemsBottom.find(m => m.menuItemName === 'mailbox')
				if (!menuItem) {
					return
				}
				menuItem.children = []
				for (const account of this.mailAccountsList) {
					if (!account.username || !account.id) {
						continue
					}
					const urlAction = (url: string) => {
						this.mailboxInnerService.setSelectedAccount(account)
						if (this.router.url === url) {
							this.mailboxInnerService.selectedEmailAccountChange$.next()
							return
						}
						this.loaderService.setContain().enable(async () => {
							await this.router.navigateByUrl(url)
						})
					}
					const menuItems = [
						{
							text: $localize`New email`,
							isThirdMenuLevel: true,
							afterClick: () => {
								urlAction('/mailbox/new')
							},
							isVisible: this.showMenuItem([Permission.MAIL_CREATE]),
						},
						{
							text: $localize`Inbox`,
							isVisible: true,
							isThirdMenuLevel: true,
							afterClick: () => {
								urlAction('/mailbox/inbox')
							},
						},
						{
							text: $localize`Outbox`,
							isVisible: true,
							isThirdMenuLevel: true,
							afterClick: () => {
								urlAction('/mailbox/outbox')
							},
						},
						{
							text: $localize`Drafts`,
							isVisible: true,
							isThirdMenuLevel: true,
							afterClick: () => {
								urlAction('/mailbox/drafts')
							},
						},
					]
					menuItem.children.push({
						text: account.username,
						isVisible: true,
						children: menuItems,
						afterClick: () => {
							urlAction('/mailbox/inbox')
						},
					})
				}
				menuItem.children.push({
					text: $localize`Contacts`,
					link: '/mailbox/contacts',
					isVisible: true,
				})
				menuItem.children.push({
					text: $localize`Mailbox settings`,
					link: '/mailbox/settings',
					isVisible: true,
				})
			},
			error: () => {
				console.error('Failed to fetch mail accounts')
			},
		})
	}

	fetchUserHasNotifications() {
		this.usersService.checkIfHasUnriddenNotifications().subscribe({
			next: value => {
				const menuItem = this.menuItems.find(m => m.menuItemName === 'dashboard')
				if (!menuItem) {
					return
				}
				if (value) {
					menuItem.customNewItemIcon = 'bell'
				} else {
					menuItem.customNewItemIcon = 'bell-no-dot'
				}
				this.menuItems = [...this.menuItems]
			},
		})
	}

	private getShortcut(event: KeyboardEvent): string {
		const specificKeys = []

		if (event.ctrlKey) specificKeys.push('Control')
		if (event.shiftKey) specificKeys.push('Shift')
		if (event.altKey) specificKeys.push('Alt')

		specificKeys.push(event.key)

		return specificKeys.join('.')
	}

	private fetchUserHasTasks(): void {
		if (this.aclService.hasPermission(Permission.ORDERS_READ)) {
			return
		}
		this.ordersInnerService.currentUserHasOrders().subscribe(hasOrders => {
			if (!hasOrders) {
				return
			}
			const menuItem = this.menuItems.find(m => m.menuItemName === 'orders')
			if (!menuItem) {
				return
			}
			const menuItemList = menuItem.children?.find(m => m.menuItemName === 'ordersList')
			if (!menuItemList) {
				return
			}
			menuItem.isVisible = true
			menuItemList.isVisible = true
		})
	}

	private fetchUserHasContractors(): void {
		if (this.aclService.hasPermission(Permission.CONTRACTORS_READ)) {
			return
		}

		this.contractorInnerService.currentUserHasContractors().subscribe(hasContractors => {
			if (!hasContractors) {
				return
			}

			const menuItem = this.menuItems.find(m => m.menuItemName === 'contractors')
			if (!menuItem) {
				return
			}

			const menuItemList = menuItem.children?.find(m => m.menuItemName === 'contractorsList')
			if (!menuItemList) {
				return
			}

			const entitiesList = menuItem.children?.find(m => m.menuItemName === 'entitiesList')
			if (!entitiesList) {
				return
			}

			menuItem.isVisible = true
			menuItemList.isVisible = true
			entitiesList.isVisible = true
		})
	}
}
