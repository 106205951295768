<div
	class="epic-loader">
	<div
		class="loader">
		<div
			class="loader-content"
			i18n>
			Loading...
		</div>
	</div>
</div>
