import { Component } from '@angular/core'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { config } from '@config'

@Component({
	selector: 'app-app-info',
	standalone: true,
	imports: [
		// TODO: `HttpClientModule` should not be imported into a component directly.
		// Please refactor the code to add `provideHttpClient()` call to the provider list in the
		// application bootstrap logic and remove the `HttpClientModule` import from this component.
		HttpClientModule,
	],
	template: `
		<div class="column">
			<div class="row align__center justify__space-between border__bottom">
				<strong>Environment:</strong>
				<pre>{{ config.environment }}</pre>
			</div>
		</div>
		<div class="column">
			<div class="row align__center justify__space-between border__bottom">
				<strong>Version:</strong>
				<pre>{{ version }}</pre>
			</div>
		</div>
		<div class="column">
			<div class="row align__center justify__space-between border__bottom">
				<strong>Version assigned date:</strong>
				<pre>{{ date }}</pre>
			</div>
		</div>
	`,
	styles: '',
})
export class AppInfoComponent {
	version: string = 'unknown'
	date: string = 'unknown'
	protected readonly config = config

	constructor(private httpClient: HttpClient) {
		this.getContent()
	}

	private getContent() {
		this.httpClient.get('VERSION.md', { responseType: 'text' }).subscribe(data => {
			const lines = data.split('\n')
			this.version = lines[0].split(': ')[1]
			this.date = lines[1].split(': ')[1]
		})
	}
}
