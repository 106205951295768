import { HttpInterceptorFn, HttpRequest } from '@angular/common/http'
import { BehaviorSubject, catchError, filter, take } from 'rxjs'
import { inject } from '@angular/core'
import { AuthService } from '@app/auth/services/auth.service'
import { Logger } from '@app/general/utils/logger.util'
import { NullableString } from '@core/types/nullable'
import { TokenRequest, TokenResponse } from '@app/auth/models/token.model'
import { switchMap } from 'rxjs/operators'

/**
 * Interceptor function for adding bearer token to the headers of an HTTP request.
 *
 * @param {string[]} allowedHosts - The list of allowed hosts for the token.
 * @return {HttpInterceptorFn} - The HTTP interceptor function.
 */
export const authInterceptor = (allowedHosts: string[]): HttpInterceptorFn => {
	let isRefreshing: boolean = false
	const logger = new Logger('Auth interceptor', {
		infoBackground: '#2c2c2c',
		successBackground: '#2c2c2c',
		infoColor: '#22b2ff',
	})
	const refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

	/**
	 * Adds bearer token to the headers of an HTTP request.
	 *
	 * @param {HttpRequest<any>} request - The original HTTP request.
	 * @param {string} token - The bearer token to be added to the request headers.
	 * @return {HttpRequest<unknown>} - The cloned HTTP request with the bearer token added to the headers.
	 */
	const addToken = (request: HttpRequest<any>, token: NullableString): HttpRequest<unknown> => {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		})
	}

	return (req, next): any => {
		if (req.url.includes('api/auth/token')) {
			return next(req)
		}

		if (allowedHosts.find(host => req.url.toLowerCase().includes(host.toLowerCase()))) {
			const authService: AuthService = inject(AuthService)

			if (authService.tokenIsSet() && !authService.accessTokenIsValid()) {
				logger.warning('The JWT token is invalid')

				if (!authService.refreshToken) {
					return next(req)
				}

				if (!isRefreshing) {
					logger.info('Refreshing the JWT')

					isRefreshing = true
					refreshTokenSubject.next(null)

					return authService.getToken(TokenRequest.withRefreshToken(authService.refreshToken)).pipe(
						switchMap((response: TokenResponse) => {
							logger.info('The JWT token has been refreshed')

							refreshTokenSubject.next(response.token)
							authService.saveToken(response.token, response.refresh_token)

							const request: HttpRequest<unknown> = addToken(req, response.token)
							return next(request)
						}),
						catchError(error => {
							logger.error('There was a problem refreshing the JWT')
							authService.logout()
							return error
						}),
					)
				} else {
					// return next(req)
					return refreshTokenSubject.pipe(
						filter(token => token != null),
						take(1),
						switchMap((token: string) => {
							const request: HttpRequest<unknown> = addToken(req, token)
							return next(request)
						}),
					)
				}
			} else {
				const request: HttpRequest<unknown> = addToken(req, authService.accessToken)
				return next(request)
			}
		} else {
			return next(req)
		}
	}
}
