import { Component, Input } from '@angular/core'
import { IconComponent } from '@core/components/icon/icon.component'

@Component({
	selector: 'internal-server-error',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './internal-server-error.page.html',
	styleUrls: ['./internal-server-error.page.scss', '../common-page.scss'],
})
export class InternalServerErrorPage {
	@Input() contactEmail?: string

	reload(): void {
		window.location.reload()
	}

	protected readonly window = window
}
