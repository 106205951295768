import { CommonModule } from '@angular/common'
import { Component, ElementRef, ViewChild } from '@angular/core'
import { LoaderComponent } from '@core/components/loader/loader.component'
import { LayoutService } from '@core/services/layout.service'
import { LoaderService, LoaderSize } from '@core/services/loader.service'
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from '@core/animations'


@Component({
	selector: 'app-layout',
	standalone: true,
	imports: [ CommonModule, LoaderComponent ],
	templateUrl: './app.layout.html',
	styleUrls: [ './app.layout.scss' ],
	animations: [
		fadeInOnEnterAnimation({ duration: 250 }),
		fadeOutOnLeaveAnimation({ duration: 250 }),
	],
})
export class AppLayout {
	@ViewChild('layoutContent') layoutContent!: ElementRef

	constructor(
		private layoutService: LayoutService,
		private loaderService: LoaderService,
	) {

	}

	ngAfterViewInit() {
		this.layoutService.layoutContainer = this.layoutContent
	}

	get isLoader(): boolean {
		return this.loaderService.getLoadingState()
	}

	afterScroll(event?: any) {
		this.layoutService.scrollState = event
	}

	get loaderSize(): LoaderSize {
		return this.loaderService.getLoaderSize()
	}

	fadeInOnEnterAnimationIsDisabled(): boolean {
		return this.loaderService.fadeInOnEnterAnimationIsDisabled()
	}
}
