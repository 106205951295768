import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, inject, ViewChild, ViewContainerRef } from '@angular/core'
import { DatepickerComponent } from '@core/components/datepicker/datepicker.component'
import { TimepickerComponent } from '@core/components/timepicker/timepicker.component'
import { DatepickerService } from '@core/services/datepicker.service'
import { ModalService } from '@core/services/modal.service'
import { NotificationService } from '@core/services/notification.service'
import { TimepickerService } from '@core/services/timepicker.service'


@Component({
	selector: 'epic-app',
	standalone: true,
	imports: [CommonModule, DatepickerComponent],
	template: `
		<ng-content />
		<div class="epic-app__notification-container">
			<ng-container #notificationContainer />
		</div>
	`,
	styleUrls: ['./app.component.scss'],
})
export class EpicAppComponent implements AfterViewInit {
	/**
	 * Represents a reference to a view container.
	 *
	 * @class
	 * @name ViewContainerRef
	 * @implements {Injectable}
	 *
	 * @description
	 * An instance of the `ViewContainerRef` class is used to reference a view container, which is responsible for managing the creation and insertion of dynamic components.
	 */
	containerRef: ViewContainerRef = inject(ViewContainerRef)

	/**
	 * The reference to the notification container.
	 *
	 * @public
	 * @type {ViewContainerRef}
	 */
	@ViewChild('notificationContainer', { read: ViewContainerRef }) notificationContainerRef!: ViewContainerRef

	constructor(
		private modalService: ModalService,
		private notificationService: NotificationService,
		private datepickerService: DatepickerService,
		private timepickerService: TimepickerService,
	) {
		this.modalService.initialize(this.containerRef)
		const datePickerComponentRef = this.containerRef.createComponent(DatepickerComponent)
		this.datepickerService.initialize(datePickerComponentRef)
		const timePickerComponentRef = this.containerRef.createComponent(TimepickerComponent)
		this.timepickerService.initialize(timePickerComponentRef)
	}

	/**
	 * Performs initialization after the view is initialized.
	 *
	 * @returns {void}
	 */
	ngAfterViewInit(): void {
		this.notificationService.initialize(this.notificationContainerRef)
	}
}
