import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http'
import { tap } from 'rxjs'
import { AuthService } from '@app/auth/services/auth.service'
import { inject } from '@angular/core'
import { AppService } from '@app/general/services/app.service'
import { NotificationService } from '@core/services/notification.service'
import { HTTPValidationError, ValidationError } from '@app/generated'

export const httpErrorHandlingInterceptor = (allowedHosts: string[]): HttpInterceptorFn => {
	return (req, next) => {
		const authService: AuthService = inject(AuthService)
		const appService: AppService = inject(AppService)
		const notificationService: NotificationService = inject(NotificationService)
		if (allowedHosts.find(host => req.url.toLowerCase().includes(host.toLowerCase()))) {
			return next(req).pipe(
				tap({
					next: () => {},
					error: (error: any) => {
						if (error instanceof HttpErrorResponse) {
							if (error.status === 401) {
								authService.logout()
							}
							if (error.status === 403) {
								notificationService.error($localize`No permission to access this resource`)
							}
							if (error.status === 500) {
								appService.showInternalServerError()
							}
							if (error.status === 422) {
								const httpValidationError: HTTPValidationError = error.error as HTTPValidationError
								if (httpValidationError?.detail && Array.isArray(httpValidationError.detail)) {
									httpValidationError.detail.forEach((elem: ValidationError) => {
										notificationService.error(elem.msg, { duration: 10000 })
									})
								}
							}
						}
					},
				}),
			)
		} else {
			return next(req)
		}
	}
}
