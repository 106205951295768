import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core'
import { ClickOutsideDirective } from '@core/directives/click-outside.directive'
import { FormsModule } from '@angular/forms'
import { IconComponent } from '@core/components/icon/icon.component'

@Component({
	selector: 'erp-command-line',
	standalone: true,
	imports: [ClickOutsideDirective, FormsModule, IconComponent],
	template: `
		<div
			class="command-line"
			[class.command-line--active]="active">
			<form
				#formElement="ngForm"
				(ngSubmit)="command.emit(input)"
				class="command-line__content"
				[class.command-line__content--active]="active"
				(clickOutside)="close.emit()">
				<div class="command-line__icon">
					<epic-icon name="search" />
				</div>
				<input
					name="input"
					[(ngModel)]="input"
					#inputElement
					autocomplete="off"
					placeholder="Type command and hit enter..." />
			</form>
		</div>
	`,
	styleUrl: './command-line.component.scss',
})
export class CommandLineComponent implements AfterViewInit {
	@Output() close = new EventEmitter<undefined>()
	@Output() command = new EventEmitter<string>()
	@ViewChild('inputElement') inputElementRef!: ElementRef

	active = false
	input: string = ''

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.active = true
			setTimeout(() => {
				this.inputElementRef.nativeElement.focus()
			}, 100) // 😅
		})
	}
}
