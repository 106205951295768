import { HttpInterceptorFn } from '@angular/common/http'
import { inject } from '@angular/core'
import { LanguageService } from '@core/services/language.service'
import { LanguageCode } from '@core/enums/language-code.enum'

export const userLanguageInterceptor = (allowedHosts: string[]): HttpInterceptorFn => {
	return (req, next) => {
		if (allowedHosts.find(host => req.url.toLowerCase().includes(host.toLowerCase()))) {
			const languageService = inject(LanguageService)

			req = req.clone({
				setHeaders: {
					'User-Language': languageService.getSelectedLanguage ?? LanguageCode.English,
				},
			})

			return next(req)
		} else {
			return next(req)
		}
	}
}
