<div class="common-page">
	<div class="common-page__content">
		<epic-icon
			name="sentiment-very-dissatisfied"
			class="common-page__icon" />
		<h1 i18n>Oops... Internal Server Error</h1>
		<h2 i18n>
			There was a problem processing your request. Try refreshing the page. If the problem persists, contact our
			technical department.
		</h2>
		<div class="common-page__actions">
			<button
				class="common-page__button"
				(click)="reload()"
				i18n>
				<epic-icon name="sort" />
				Refresh the page
			</button>
		</div>
		<div
			class="common-page__support"
			*ngIf="contactEmail">
			{{ contactEmail }}
		</div>
	</div>
</div>
