<div
	[class.epic-menu--collapsed]="collapseValue()"
	[class.epic-menu--alternative]="isAlternativeDesign"
	class="epic-menu">
	<div
		(click)="toggleCollapse()"
		class="epic-menu__resize-button">
		<epic-icon name="collapse" />
	</div>
	<div>
		<ng-content select="[menu-top]" />
	</div>
	@if (isAlternativeDesign) {
		<div class="epic-menu__alternative-scroll">
			<div class="epic-menu__alternative-wrapper">
				<div [ngTemplateOutlet]="menuCenterTemplate"></div>
				<div [ngTemplateOutlet]="menuBottomTemplate"></div>
			</div>
		</div>
	} @else {
		<div class="epic-menu__center">
			<div [ngTemplateOutlet]="menuCenterTemplate"></div>
		</div>
		<div class="epic-menu__bottom">
			<div [ngTemplateOutlet]="menuBottomTemplate"></div>
		</div>
	}
</div>

<ng-template #menuCenterTemplate>
	<ng-content select="[menu-item]" />
</ng-template>

<ng-template #menuBottomTemplate>
	<ng-content select="[menu-bottom]" />
</ng-template>