import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { LoaderService, LoaderSize } from '@core/services/loader.service'

@Component({
	selector: 'epic-loader',
	standalone: true,
	imports: [ CommonModule ],
	templateUrl: './loader.component.html',
	styleUrls: [ './loader.component.scss' ],
})
export class LoaderComponent {
	constructor(private loaderService: LoaderService) {

	}
}
