import { NgFor, NgIf } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { fadeInOutDynamicAnimation } from '@core/animations/fading-entrances/fade-in.animation'
import { ClickOutsideDirective } from '@core/directives/click-outside.directive'
import { LanguageCode } from '@core/enums/language-code.enum'
import * as ISOLanguages from '@core/i18n/i18n-iso-languages'
import { LanguageService } from '@core/services/language.service'
import { IconComponent } from '@core/components/icon/icon.component'

@Component({
	selector: 'epic-language-switcher',
	standalone: true,
	imports: [NgFor, NgIf, ClickOutsideDirective, IconComponent],
	templateUrl: './language-switcher.component.html',
	styleUrl: './language-switcher.component.scss',
	animations: [fadeInOutDynamicAnimation],
})
export class LanguageSwitcherComponent implements OnInit {
	selectedLanguage?: LanguageCode
	@Input() acceptableLanguages!: LanguageCode[]
	@Output() change: EventEmitter<LanguageCode> = new EventEmitter<LanguageCode>()

	isOpen = false

	constructor(private languageService: LanguageService) {}

	/**
	 * Initializes the component.
	 *
	 * This method is called by Angular when the component is being initialized.
	 * It registers the JSON representation of each acceptable language into the ISOLanguages.
	 *
	 * @return {void} Nothing is returned by this method.
	 */
	ngOnInit(): void {
		setTimeout(() => {
			this.selectedLanguage = this.languageService.getSelectedLanguage
			if (!this.acceptableLanguages) {
				this.acceptableLanguages = this.languageService.getAcceptableLanguages
			}

			if (!this.selectedLanguage) {
				this.selectedLanguage = this.languageService.getLocalStorageLanguage
			}

			this.acceptableLanguages.forEach(language => ISOLanguages.registerJSON(this.getLangCode(language)))
		})
	}

	/**
	 * Changes the language of the application.
	 *
	 * @param {LanguageCode} language - The language code to set.
	 * @return {void}
	 */
	setLanguage(language: LanguageCode): void {
		this.close()
		if (language.split('-').length > 1) {
			const code = language.split('-')
			this.change.emit(`${code[0].toLowerCase()}-${code[1].toUpperCase()}` as LanguageCode) // :)
		} else {
			this.change.emit(language)
		}
	}

	/**
	 * Retrieves the name of a language in the selected language.
	 * @param {string} language - The language code of the desired language.
	 * @return {string} - The translated name of the language, or the language code if no translation is available.
	 */
	getLanguageName(language: LanguageCode): string {
		if (!this.selectedLanguage) {
			return language
		}

		const translatedLanguage: string | undefined = ISOLanguages.getName(
			this.getLangCode(language),
			this.getLangCode(this.selectedLanguage ?? 'en'),
		)

		return translatedLanguage ?? language
	}

	/**
	 * Toggle the state of the object.
	 * If it is open, close it. If it is closed, open it.
	 *
	 * @return {void}
	 */
	toggle(): void {
		this.isOpen ? this.close() : this.open()
	}

	/**
	 * Sets the `isOpen` property to `true`.
	 *
	 * @method open
	 * @return {void}
	 */
	open(): void {
		this.isOpen = true
	}

	/**
	 * Closes the current instance.
	 *
	 * @return {void} - Does not return any value.
	 */
	close(): void {
		this.isOpen = false
	}

	getIconSource(lang: string): string {
		if (lang) {
			const code = this.getLangCode(lang)
			return `url('icons/flags/language/${code}.svg')`
		}
		return ''
	}

	private getLangCode(lang: string): string {
		return lang.split('-')[0].toLowerCase()
	}
}
