import { ApplicationConfig } from '@angular/core'
import { provideRouter } from '@angular/router'

import { routes } from './app.routes'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { authInterceptor } from '@app/auth/http-interceptors/auth.interceptor'
import { config } from '@config'
import { BASE_PATH } from '@app/generated/'
import { httpErrorHandlingInterceptor } from '@app/general/http-interceptor/http-error-handling.interceptor'
import { userLanguageInterceptor } from '@app/general/http-interceptor/user-language.interceptor'
import { provideCharts, withDefaultRegisterables } from 'ng2-charts'

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(routes),
		provideAnimations(),
		provideHttpClient(
			withInterceptors([
				authInterceptor(config.allowedHosts),
				httpErrorHandlingInterceptor(config.allowedHosts),
				userLanguageInterceptor(config.allowedHosts),
			]),
		),
		{
			provide: BASE_PATH,
			useValue: config.api.url,
		},
		provideCharts(withDefaultRegisterables()),
	],
}
